import { FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gridSpacing } from "store/constant";
import axiosServices from "utils/axiosServices";
import { permission } from "utils/permission";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconVolume } from "@tabler/icons";
import ViewDayIcon from "@mui/icons-material/ViewDay";

const IncidentView = () => {
  const [details, setDetails] = useState<any>([]);
  const [incidentInfo, setIncidentInfo] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState<any[]>([]);
  const [volunteerList, setVolunteerList] = useState<any[]>([]);
  const [date, setDate] = useState<any>();
  // console.log("date", date);
  const param = useParams();
  const SL = useParams().serial;
  const access = permission();

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData = { incidentUuid: param.id };

        const response = await axiosServices.post(
          `incident/get-details`,
          postData
        );
        console.log(response.data);

        if (response.status === 200) {
          setIncidentInfo(response.data?.incident.result[0])
          setDetails(response.data?.incidentDetail);
          setVehicle(response.data?.vehicleList);
          setVolunteerList(response.data?.volunteerList);
          const date = new Date(
            response.data?.incidentDetail.dateAndTime
          ).toUTCString();
          setDate(
            new Date(response.data?.incidentDetail.dateAndTime)
              .toUTCString()
              .substring(0, date.length - 3)
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [param.id]);

  const getDate = (date: string) => {
    return new Date(date);
  };

  return (
    <Wrapper
      title={`Incident SL no. ${incidentInfo.sl_no} Information Details`}
      editLink={`/incident-report/${param.id}/incident-edit`}
      backLink="/reports/incidents-report"
      permission={access.includes("Incident-Edit")}
    >
      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography variant="h4">Incident Identification</Typography>
        <Grid
          container
          spacing={gridSpacing}
          sx={{ mt: "5px" }}
          alignItems="center"
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Date of Crash and Time:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {date}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Time of Volunteer Arrival at Scene:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.timeofArrival}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={gridSpacing}
          sx={{ mt: "1px" }}
          alignItems="center"
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Upazilla:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.upazila}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Zone:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.zoneName}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ mt: "1px" }}>
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Location of Crash:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.location}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Caller:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.caller}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography variant="h4">Volunteer Data</Typography>
        <Grid
          container
          spacing={gridSpacing}
          sx={{ mt: "5px" }}
          alignItems="center"
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Number of Volunteers Present :
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.volunteerCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Volunteer Response Time (From crash) (minutes) :
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.responseTimeVol}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Volunteer Communication:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.communicationMethod}
            </Typography>
          </Grid>

          {details.volunteerCount > 0 && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Volunteer List :
              </Typography>
            </Grid>
          )}

          {volunteerList?.map((volunteer: any) => (
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Grid container alignItems="center">
                  <ViewDayIcon />
                  <Typography sx={{ mx: 5 }}>{volunteer.id}</Typography>
                </Grid>
                <Grid container alignItems="center">
                  <AccountCircleIcon />
                  <Typography sx={{ mx: 5 }}>{volunteer.full_name}</Typography>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography variant="h4">Field Staff Data</Typography>

        <Grid
          container
          spacing={gridSpacing}
          alignItems="center"
          sx={{ mt: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Number of Field Staff Present:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.fieldStaffCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
              Field Staff Response Time (minutes):
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.fieldStaffResponseTime &&
                `${details.fieldStaffResponseTime} mins`}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
              Field Staff List :
            </Typography>
          </Grid>

          {details.fieldStaff?.map((volunteer: any) => (
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Grid container alignItems="center">
                  <AccountCircleIcon />
                  <Typography sx={{ mx: 5 }}>{volunteer}</Typography>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography sx={{ marginBottom: 1 }} variant="h4">
          Injured Data
        </Typography>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ mt: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Total number of vehicle occupants:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.vehicleOccupantCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Total number of injured:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.totalInjuredCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
              Number of injured receiving treatment at the scene by TL staff and
              released to go home:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.tlTreatReleaseCount}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Number of injured who received treatment and taken to hospital
              later by TL staff:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.tlTreatHospitalCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Number of injured sent to hospital immediately by TL staff without
              treatment:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.tlImmeHospitalCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
              Number of injured who refused treatment due to low injury
              severity:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.noTreatCount}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Number of injured who refused treatment and went to hospital:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.noTreatHospitalCount}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Number dead at the scene
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.casualtyCount}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          Other responders
        </Typography>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Were the Local Police Present:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.localPolicePresent ? "Yes" : "No"}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "right" }}>
              Were the Highway Police Present:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.highwayPolicePresent ? "Yes" : "No"}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "right" }}>
              Was the Fire Brigade Present:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details.fireBrigadePresent ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {vehicle.length !== 0 ? (
        <Paper elevation={2} sx={{ padding: 3, marginLeft: 2 }}>
          <Typography variant="h4">
            Number of Vehicles Involved in Crash
          </Typography>

          <Grid container spacing={gridSpacing} sx={{ marginTop: "2px" }}>
            {/* <Grid item xs={12}>
              <Typography variant="h2" sx={{ marginTop: 2, textAlign: "left" }}>
                Number of Vehicles Involved in Crash :
              </Typography>
            </Grid> */}

            {vehicle.map((item: any) => (
              <Grid item xs={12}>
                <Typography sx={{ marginBottom: 1 }}>
                  <Grid container style={{ marginLeft: "5px" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.value} :{" "}
                    </Typography>{" "}
                    <Typography>{" " + item.count}</Typography>
                  </Grid>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
      ) : null}
    </Wrapper>
  );
};

export default IncidentView;
