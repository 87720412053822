import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import Wrapper from "components/Card/Wrapper";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axiosServices from "utils/axiosServices";
import { injuriesCount } from "utils/Helpers";
// import { injuriesCount } from 'utils/Helpers';

const InjuryAsse = () => {
  const servedStatusList = ['All','Served','Not Served'];
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const graphData = injuriesCount(rows);
  const [clinicalOutcome, setClinicalOutcome] = useState<any>([]);
  const [toDateRange, setToDateRange] = useState<any>();
  const [fromDateRange, setFromDateRange] = useState<any>();
  const [servedStatus, setServedStatus] = useState(servedStatusList[0])
  console.log("clinicalOutcome", clinicalOutcome);
  console.log("rows: ", rows);

  const series: any = [
    {
      name: "Mild Injuries",
      data: graphData.light,
    },
    {
      name: "Moderate Injuries",
      data: graphData.moderate,
    },
    {
      name: "Severe Injuries",
      data: graphData.severe,
    },
    {
      name: "Spot Death",
      data: graphData.spotDead,
    },
  ];

  const options: any = {
    chart: {
      type: "bar",
      height: 500,
      width: "20%",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#f0f"],
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: clinicalOutcome,

      labels: {
        formatter: function (val: any) {
          return parseFloat(val).toFixed(2);
        },
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        maxWidth: 500,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      try {
        const response = await axiosServices.post(`report/injuryAssessment`);

        if (response.status === 200) {
          // console.log("response", response.data);
          setRows(response.data);
          setClinicalOutcome(Object.keys(response.data));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, []);

  const handleFilter = async () => {
    const body = {
      startDate: fromDateRange,
      endDate: toDateRange,
      servedStatus: servedStatus
    };
    try {
      const response = await axiosServices.post(
        `report/injuryAssessment`,
        body
      );
      if (response.status === 200) {
        setRows(response.data);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Wrapper title="Injury Assesment">
      <Grid
        container
        alignItems="center"
        sx={{ marginBottom: "40px" }}
        spacing={2}
      >
        <Grid item xs={2} sx={{ mx: "10" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Report Filter By Date Range
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>From</Typography>
          <TextField
            type="date"
            onChange={(e) => setFromDateRange(e.target.value)}
          />
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>To</Typography>
          <TextField
            type="date"
            onChange={(e) => setToDateRange(e.target.value)}
          />
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>Served Status</Typography>
          <Autocomplete
              options={servedStatusList}
              value={servedStatus}
              onChange={(event, newValue) => {
                  setServedStatus(newValue ?? '');
              }}
              renderInput={(params) => <TextField {...params} label="" />}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sm={2}
          sx={{ textAlign: "left" }}
          style={{ alignContent: "center" }}
        >
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>
        </Grid>
      </Grid>

      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={450}
        />
      </div>
      <Box sx={{ marginTop: 5 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell colSpan={1}></TableCell>
              <TableCell colSpan={4}>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  First Responder Assessment of Injury Severity
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  Mild Injuries
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  Moderate Injuries
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  Severe Injuries
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  Spot Death
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color={"black"} style={{ fontWeight: 600 }}>
                  Grand Total
                </Typography>
              </TableCell>
            </TableRow>

            {Object.keys(rows).map((item: any, index) => (
              <TableRow>
                {index === 0 ? (
                  <TableCell rowSpan={Object.keys(rows).length}>
                    <Typography color={"black"}>Clinical Outcome</Typography>
                  </TableCell>
                ) : (
                  ""
                )}
                <TableCell>
                  <Typography color={"black"}>{item}</Typography>
                </TableCell>
                <TableCell>{rows[item].Mild}</TableCell>
                <TableCell>{rows[item].Moderate}</TableCell>
                <TableCell>{rows[item].Severe}</TableCell>
                <TableCell>{rows[item]["Spot death"]}</TableCell>
                <TableCell>{rows[item].Total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Wrapper>
  );
};

export default InjuryAsse;
