import { Box, Typography, Grid, TextField, Button, Autocomplete } from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import BasicDataTable, { ColumnType } from "components/common/BasicDataTable";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axiosServices from "utils/axiosServices";
import { genderPatientCount } from "utils/Helpers";
import langString from "utils/langString";

const PatientTime = () => {
  const servedStatusList = ['All','Served','Not Served'];

  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<any>([]);
  const [page, setPage] = useState<any>([]);
  const [toDateRange, setToDateRange] = useState<any>();
  const [fromDateRange, setFromDateRange] = useState<any>();

  const [startIndex, setStartIndex] = useState(0);
  const [servedStatus, setServedStatus] = useState(servedStatusList[0])

  console.log("rows", rows);

  const Columns: ColumnType[] = [
    {
      header: "Age",
      accessor: "Age",
      content: (item: any) => {
        if (item.minAge === 71) {
          return <Typography>{` > ${item.minAge}`}</Typography>;
        } else if (!item.minAge && !item.maxAge) {
          return <Typography>Total</Typography>;
        } else {
          return <Typography>{`${item.minAge} - ${item.maxAge}`}</Typography>;
        }
      },
    },
    {
      header: "Male",
      accessor: "male",
      content: (item: any) => <Typography>{item.male}</Typography>,
    },
    {
      header: "Female",
      accessor: "Female",
      content: (item: any) => <Typography>{item.female}</Typography>,
    },
    {
      header: "Unknown",
      accessor: "unknowngender",
      content: (item: any) => <Typography>{item.unknowngender}</Typography>,
    },
    {
      header: "Total",
      accessor: "total",
      content: (item: any) => <Typography>{item.total}</Typography>,
    },
  ];

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const response = await axiosServices.post(`report/patientGender`);
        if (response.status === 200) {
          setRows(response.data);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, []);

  const handleFilter = async () => {
    const body = {
      startDate: fromDateRange,
      endDate: toDateRange,
      servedStatus: servedStatus
    };
    try {
      const response = await axiosServices.post(`report/patientGender`, body);
      if (response.status === 200) {
        setRows(response.data);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const count = genderPatientCount(JSON.parse(JSON.stringify(rows)));

  const series: any = [
    {
      name: "Male",
      data: count.male,
    },
    {
      name: "Female",
      data: count.female,
    },
    {
      name: "Unknown Gender",
      data: count.unknownGender,
    },
  ];

  const options: any = {
    chart: {
      type: "bar",
      height: 500,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "0-10",
        "11-20",
        "21-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        " > 70",
        "Unknown age",
      ],
    },
    yaxis: {
      title: {
        text: "Number of Patients",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + " Patients";
        },
      },
    },
  };

  return (
    <Wrapper title={`${langString("Age of Patients by Gender")} `}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} sx={{ mx: "10" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Report Filter By Date Range
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>From</Typography>
          <TextField
            type="date"
            onChange={(e) => setFromDateRange(e.target.value)}
          />
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>To</Typography>
          <TextField
            type="date"
            onChange={(e) => setToDateRange(e.target.value)}
          />
        </Grid>
        <Grid item xs={3} sm={2} sx={{ textAlign: "left" }}>
          <Typography>Served Status</Typography>
          <Autocomplete
              options={servedStatusList}
              value={servedStatus}
              onChange={(event, newValue) => {
                  setServedStatus(newValue ?? '');
              }}
              renderInput={(params) => <TextField {...params} label="" />}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sm={2}
          sx={{ textAlign: "left" }}
          style={{ alignContent: "center" }}
        >
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>
        </Grid>
      </Grid>

      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <Box sx={{ marginTop: 5 }}>
        <BasicDataTable
          columns={Columns}
          rows={rows}
          count={0}
          page={1}
          rowsPerPage={10}
          setPage={(value: number) => setPage(value)}
          setRowsPerPage={(value: number) => setRowsPerPage(value)}
        />
      </Box>
    </Wrapper>
  );
};

export default PatientTime;
